<template>
	<nav>
		<!-- Navigation Header (toggler) -->
		<div class="root-nav" @click="handleRootNav" :class="{ open: rootNavOpened }">
			{{ selectedBrand?.title }}
		</div>

		<!-- Mobile only -->
		<div class="category" @click="handleMobileCategoryNav" :class="{ open: isUlOpen }" v-show="!rootNavOpened">
			{{ selectedCategory?.title }}
		</div>

		<!-- Root Level Navigation (Brands) -->
		<ul v-show="rootNavOpened">
			<li v-for="{ id: bid, status: bstatus, slug: bslug, title: btitle } in brands" :class="{ disabled: bstatus === 1, current: bid === selectedBrand.id }" :key="`nav-brand-${bid}`" @click="bstatus !== 1 && toggleRootNav()" class="first-menu">
				<router-link custom v-slot="{ navigate }" :to="`/fundamental-guidelines/${bslug}`">
					<a @click="bstatus !== 1 ? navigate() : ''">{{ btitle }}</a>
				</router-link>
			</li>
			<div class="dark-block">
				<DarkMode />
				<ButtonGridMode />
			</div>
		</ul>

		<!-- Second Level Navigation (Categories) -->
		<ul v-show="(!mobile && !rootNavOpened) || (mobile && isUlOpen && !rootNavOpened)" >
			<li v-for="{ id, status, slug, title, contents } in categories" :key="`nav-category-${id}`" :class="{ current: id === selectedCategory.id, disabled: status === 1 }" class="first-menu">
				<router-link custom exact v-slot="{ navigate }" :to="`/fundamental-guidelines/${selectedBrand.slug}/${slug}`">
					<a @click="status !== 1 ? navigate() : ''">{{ title }}</a>
				</router-link>
				<!-- Third Level Navigation (Contents) -->
                <template v-if="(slug !== 'advertising' && selectedBrand?.slug == 'renault'  || selectedBrand?.slug !== 'renault') ">
				<ul v-show="selectedCategory.id === id && contents.length">
					<li
						v-for="{ id: cid, status: cstatus, slug: cslug, title: ctitle } in contents"
                        
						:key="`nav-content-${cid}`"
						:id="`link-${cslug}`"
						:class="{
							disabled: cstatus === 1,
							selected: selectedContent?.slug === cslug,
						}"
						@click="test"
						class="navigation"
					>
						<router-link custom v-slot="{ navigate }" :id="`link-${cslug}`" exact :to="`/fundamental-guidelines/${selectedBrand?.slug}/${selectedCategory?.slug}#${cslug}`" >
							<a
								v-if="cstatus !== 1"
								@click="
									navigate();
									handleMobileComponentNav();
								"
							>
								{{ ctitle }}
							</a>
							<a v-else>{{ ctitle }}</a>
						</router-link>
					</li>
				</ul>
            </template>
			</li>
			<div class="dark-block">
				<DarkMode />
				<ButtonGridMode />
			</div>
		</ul>
	</nav>
</template>

<script>
import { inject, onMounted, ref } from "@vue/runtime-core";
import { useToggle } from "@/hooks/useToggle";
import isMobile from "is-mobile";
import DarkMode from "@/components/organisms/DarkMode.vue";
import ButtonGridMode from "@/components/organisms/ButtonGridMode.vue";
import { useRoute } from "vue-router";
export default {
	name: "ONavigation",
	emits: ["rootNav", "test"],
	components: {
		DarkMode,
		ButtonGridMode,
	},

	setup(_, { emit }) {
		const { brands, selectedBrand, selectedCategory, categories, selectedContent } = inject("navigation");

		const { state: rootNavOpened, toggle: toggleRootNav } = useToggle();
		const { state: isUlOpen, toggle: toggleUl } = useToggle();

		const mobileMode = ref(null);

		const route = useRoute();

		onMounted(() => {
			mobileMode.value = Boolean(route.query.mobile);
			mobileMode.value === true ? (rootNavOpened.value = false) : (rootNavOpened.value = true);
			mobileMode.value === true ? (isUlOpen.value = false) : (isUlOpen.value = true);
		});

		const handleRootNav = () => {
			toggleRootNav();
			if (!rootNavOpened.value === false) isUlOpen.value = false;
			emit("rootNav", rootNavOpened.value);
		};
		const handleMobileCategoryNav = () => {
			toggleUl();
			if (isUlOpen.value === false) rootNavOpened.value = false;
			emit("rootCategory", isUlOpen.value);
		};

		const handleMobileComponentNav = () => {
			if (isMobile()) {
				handleMobileCategoryNav();
			}
		};

		return {
			brands,
			categories,
			selectedBrand,
			selectedCategory,
			selectedContent,
			toggleRootNav,
			rootNavOpened,
			toggleUl,
			isUlOpen,
			mobile: isMobile(),
			handleRootNav,
			handleMobileCategoryNav,
			handleMobileComponentNav,
		};
	},
};
</script>

<style lang="scss" scoped>
.dark-block {
	margin-top: 100px;
	display: flex;
	//margin-right: -32px;
	box-sizing: border-box;
	margin-left: -90px;
	@media screen and (max-width: 1200px) {
		margin-top: 50px;
		margin-left: -50px;
	}
}
nav {
	position: sticky;
	position: -webkit-sticky;
	top: 100px;
	display: flex;
	flex-direction: column;
	width: 334px;
	min-width: 335px;
	z-index: 2;
	//max-height: 100vh;
	//padding-top: 100px;
	box-sizing: border-box;
	font-size: 16px;
	@media screen and (max-width: 1200px) {
		top: 0px;
		padding-top: 0;
	}
	.root-nav {
		display: flex;
		justify-content: space-between;
		padding: 22px 32px;
		font-weight: 700;
		border-bottom: 1px solid var(--darker);
		cursor: pointer;
		@media screen and (max-width: 1200px) {
			margin-left: -20px;
			margin-right: -25px;
		}
		&.open {
			&::after {
				//transform: rotate3d(1, 0, 0, 180deg);
				transform: rotate(0deg);
			}
		}
		&::after {
			content: "";
			width: 16px;
			height: 16px;
			background-image: url("../../assets/arrow-filter-black.svg");
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			//transition: all 0.5s ease-in-out;
			transform: rotate(180deg);
		}
	}
	.category {
		display: none;
		justify-content: space-between;
		padding: 22px 32px;
		font-weight: 700;
		border-bottom: 1px solid var(--darker);
		position: relative;
		cursor: pointer;
		@media screen and (max-width: 1200px) {
			margin-left: -20px;
			margin-right: -25px;
		}
		&.open {
			&::after {
				transform: rotate(0deg);
				//transform: rotate(180deg);
				bottom: 30px;
			}
			&::before {
				//transform: rotate3d(1, 0, 0, 0deg);
				transform: rotate(180deg);
				top: 30px;
			}
		}
		&::after {
			position: absolute;
			right: 32px;
			bottom: 30px;
			content: "";
			width: 16px;
			height: 16px;
			background-image: url("../../assets/arrow-filter-black.svg");
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			//transition: all 0.5s ease-in-out;
		}
		&::before {
			position: absolute;
			right: 32px;
			top: 30px;
			content: "";
			width: 16px;
			height: 16px;
			background-image: url("../../assets/arrow-filter-black.svg");
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			//transition: all 0.5s ease-in-out;
			//transform: rotate3d(1, 0, 0, 180deg);
			transform: rotate(180deg);
		}
	}
	.first-menu {
		margin-top: 15px;
		&:first-child {
			margin-top: 0;
		}
	}
	ul {
		list-style: none;
		padding: 32px;
		padding-left: 130px;
		font-size: 16px;
		font-weight: 700;
		color: var(--darker);
		overflow-y: auto;
		margin: 0;
		height: auto;
		position: relative;
		li {
			//min-height: 32px;
			padding: 0;
			margin-top: 10px;
			a {
				text-decoration: none;
				margin: 0;
				font-weight: 700;
				&:hover {
					color: var(--darker);
				}
			}
			ul {
				font-weight: 700;
				padding: 0px 0 0px 16px;
				min-height: 32px;
				li {
					&.selected {
						position: initial;
						color: var(--darker);
						font-weight: 700;
						display: flex;
						a {
							font-weight: 700;
						}
					}
					&.active {
						font-weight: 700;
						a {
							font-weight: 700;
						}
					}
					&.disabled {
						a {
							color: var(--grey);
							display: flex;
							&:hover {
								&::after {
									content: "soon !";
									margin-left: 16px;
									font-size: 16px;
									background-color: var(--yellow2);
									font-weight: 700;
									color: var(--darker);
									padding: 0;
								}
							}
						}
					}
				}
			}
			&.current {
				position: relative;
				color: var(--darker);
				li {
					a {
						font-weight: 400;
					}
				}
				a {
					font-weight: 700;
				}
				&::before {
					position: absolute;
					content: "";
					left: -32px;
					width: 16px;
					height: 23px;
					background-image: url("../../assets/icon-select.svg");
					background-size: contain;
					background-position: center;
					background-repeat: no-repeat;
				}
			}
			&.disabled {
				a {
					color: var(--grey);
					&:hover {
						&::after {
							content: "soon !";
							margin-left: 16px;
							font-size: 16px;
							background-color: var(--yellow2);
							color: #000;
							padding: 3px;
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1200px) {
	#components {
		flex-direction: column;
		nav {
			position: relative;
			width: 100%;
			min-width: 100%;
			border-right: none;
			transition: all 0.6s ease-in-out;
			background-color: var(--lighter);
			z-index: 0;
			left: 0;
			.root-nav {
				filter: invert(0);
				background-color: var(--darker);
				color: var(--lighter);
				&::after {
					filter: invert(1);
				}
				&:hover {
					background-color: var(--darker);
				}
			}
			.category {
				display: flex;
			}
			ul {
				height: 100vh;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: flex-start;
				padding-left: 50px;
				ul {
					height: fit-content;
				}
				&.hide {
					display: none;
				}
			}
		}
	}
}

body.dark-mode {
	nav ul li.current::before {
		filter: invert(1);
	}
	nav .root-nav::after {
		background-image: url("../../assets/arrow-filter-white.svg");
	}
	nav {
		.category {
			&::after,
			&::before {
				filter: invert(1);
			}
		}
	}
	@media screen and (max-width: 1200px) {
		#components {
			nav {
				.root-nav {
					&::after {
						filter: invert(1);
					}
				}
			}
		}
	}
}
</style>